import React, { useState, useRef, useEffect } from "react";

import { Selector, Minutes, Minute } from "./style";

interface Props {
	minutes: number[];
	minute: number;
	setMinute(m: number): void;
}

const MinuteSelector: React.FC<Props> = ({ minutes, minute, setMinute }) => {
	const [scroll, setScroll] = useState(1);

	const minuteEl = useRef<HTMLDivElement>(null);

	useEffect(() => {
		setMinute(Number((scroll / 80).toFixed(0)) + minutes[0]);
	}, [scroll, minutes]);

	return (
		<Selector
			ref={minuteEl}
			onScroll={() => {
				if (minuteEl && minuteEl.current) {
					const newScroll = minuteEl.current.scrollTop;
					if (Math.abs(newScroll - scroll) > 5) {
						setScroll(newScroll);
					}
				}
			}}
		>
			<Minutes>
				{minutes.map(m => (
					<Minute key={m} diff={Math.abs(minute - m)}>
						{m}'
					</Minute>
				))}
			</Minutes>
		</Selector>
	);
};
export default MinuteSelector;
