import React, { useState, useEffect, useContext } from "react";

import { ChoiceContext } from "../../contexts/ChoiceContext";

import { Link } from "react-router-dom";
import MinuteSelector from "../../components/MinuteSelector";
import { Page } from "./style";
import { Button, BlueButton } from "../style";

const sections = ["start", "mid", "end"];

const Minute: React.FC = () => {
	const [section, setSection] = useState(0);

	const { playerName, minute, setMinute } = useContext(ChoiceContext);

	return (
		<Page>
			<div className="header">
				Which minute will {playerName} score in?
			</div>
			<div className="selector">
				<div className="match-sections">
					{["first", "second"].map((half, i) => (
						<div className="half">
							<div className={`${half} half-type`}>
								{half} half
							</div>
							{sections.map((s, j) => (
								<div
									className={`${s} section ${section ===
										i * 3 + j && "active"}`}
									onClick={() => setSection(i * 3 + j)}
								>
									{s}
								</div>
							))}
							{!i && <hr />}
						</div>
					))}
				</div>
				<MinuteSelector
					minutes={Array.from(
						Array(15),
						(x, i) => i + (section * 15 + 1)
					)}
					minute={minute}
					setMinute={setMinute}
				/>
			</div>
			<div className="footer">
				<Link to="/player">
					<Button>Back</Button>
				</Link>
				<Link to="/summary">
					<BlueButton>Next</BlueButton>
				</Link>
			</div>
		</Page>
	);
};

export default Minute;
