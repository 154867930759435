import styled from "styled-components";

export const Page = styled.div`
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background: lightgray;

	.card {
		height: 470px;
		width: 100%;
		max-width: 320px;
		margin: 0 22px;
		background: #0f204c;
		border-radius: 7px;

		.subheader {
			height: 35px;
			text-transform: uppercase;
			color: white;
			text-align: center;
			font-size: 2.2rem;
			padding: 8px;
		}

		.fixture {
			height: 73px;
			color: white;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.side {
				background: black;
				font-size: 1.4rem;
				text-transform: uppercase;
				padding-top: 9px;
				padding-bottom: 9px;
				position: relative;
			}

			.home {
				padding-left: 40px;
				z-index: 1;

				::before {
					content: "";
					position: absolute;
					top: 0;
					left: 94px;
					right: 0;
					bottom: 0;
					background: black;
					z-index: -1;
					width: 27px;
					padding: 1rem;
					transform: skew(-21.5deg);
				}

				::after {
					content: "";
					position: absolute;
					top: 8px;
					left: 115px;
					right: 0;
					bottom: 0;
					background: white;
					z-index: -1;
					width: 10px;
					height: 30px;
					transform: skew(-21.5deg);
				}
			}

			.away {
				padding-right: 45px;
				z-index: 1;

				::before {
					content: "";
					position: absolute;
					top: 0;
					left: -15px;
					right: 0;
					bottom: 0;
					background: black;
					z-index: -1;
					width: 40px;
					padding: 1rem;
					transform: skew(-21.5deg);
				}

				::after {
					content: "";
					position: absolute;
					top: 0;
					left: -17px;
					right: 0;
					bottom: 0;
					background: white;
					z-index: -1;
					width: 10px;
					height: 30px;
					transform: skew(-21.5deg);
				}
			}

			.vs {
				background: black;
				height: 38px;
				padding-top: 9px;
				width: 25px;
				text-align: center;
				position: relative;
				z-index: 1;

				::before {
					content: "";
					position: absolute;
					top: 0;
					left: -8px;
					right: 0;
					bottom: 0;
					background: black;
					z-index: -1;
					width: 40px;
					padding: 1rem;
					transform: skew(-21.5deg);
				}
			}
		}

		.prize {
			height: 70px;
			color: white;
			text-align: center;
			font-size: 5.5rem;
			padding-top: 30px;
		}

		.picks {
			height: 80px;
			display: flex;
			justify-content: space-evenly;

			.pick {
				margin-top: 10px;
				background: white;
				width: 135px;
				height: 58px;
				border-radius: 6px;
				text-transform: uppercase;
				font-size: 2.5rem;
				text-align: center;
				padding-top: 22px;
			}
		}

		.optin {
			display: flex;
			color: white;
			font-family: "lato";
			font-weight: bold;
			font-size: 0.97rem;
			padding-top: 15px;
			padding-left: 15px;
			justify-content: space-between;
		}
	}
`;

interface SwitchProps {
	optedIn: boolean;
}

export const Switch = styled.div<SwitchProps>`
	background: white;
	height: 30px;
	width: 50px;
	margin-right: 17px;
	margin-top: -5px;
	border-radius: 15px;

	.inner-circle {
		background: #47b6e3;
		height: 22px;
		width: 22px;
		margin-top: 4px;
		margin-left: ${({ optedIn }) => (optedIn ? "24px" : "4px")};
		border-radius: 50%;
		transition: margin-left 0.1s ease-in-out;
	}
`;
