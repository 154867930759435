import styled from "styled-components";

interface ButtonProps {
	disabled?: boolean;
}

export const Button = styled.button<ButtonProps>`
	width: 170px;
	margin: 0 3px;
	height: 48px;
	border: none;
	border-radius: 5px;
	outline: none;
	cursor: pointer;
    opacity:${({ disabled }) => (disabled ? 0.4 : 1)}

	:active {
		-webkit-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
		-moz-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
		box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
	}
`;

export const BlueButton = styled(Button)`
	background: #0f204c;
	color: white;
`;

interface HeaderProps {
	small?: boolean;
}

export const Header = styled.div<HeaderProps>`
	height: 70px;
	text-align: center;
	color: white;
	text-transform: uppercase;
	font-size: ${({ small }) => (small ? "2rem" : "2.5rem")};
	display: flex;
	align-items: center;
	justify-content: center;

	.content {
		margin-top: 20px;
		position: relative;
		z-index: 1;
	}

	.content:before {
		content: "";
		position: absolute;
		top: ${({ small }) => (small ? "-11px" : "-17px")};
		left: ${({ small }) => (small ? "-32px" : "-45px")};
		right: 0;
		bottom: 0;
		background: #47b6e3;
		z-index: -1;
		width: ${({ small }) => (small ? "173px" : "227px")};
		height: ${({ small }) => (small ? "38px" : "51px")};
		padding: 1rem;
		transform: ${({ small }) => (small ? "skew(-20deg)" : "skew(-28deg)")};
	}
`;

export const TimeWithButton = styled.div`
		height: 45px;
		display: flex;
		justify-content: space-evenly;
		padding: 0 10px;

		.time-container {
			background: black;
			width: 95px;
			border-radius: 6px;
			color: white;
			text-align: center;
			margin-right:8px;

			* {
				font-family: "lato";
			}

			.time {
				font-size: 1.2rem;
				padding-top: 6px;
				font-weight: bold;

				span {
					font-size: 0.7rem;
				}
			}

			.prompt {
				text-transform: uppercase;
				font-size: 0.65rem;
				margin-top: -6px;
			}
		}

		button {
			width: 180px;
			height: 45px;
			background: #47b6e3;
			border: none;
			outline: none;
			border-radius: 6px;
			color: white;
			text-transform: capitalize;
		}
	}
`;
