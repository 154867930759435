import styled from "styled-components";

export const Page = styled.div`
	height: 100vh;
	width: 100vw;
	background: #1c1f28;

	.header {
		height: 115px;
		color: white;
		text-transform: uppercase;
		font-size: 2.5rem;
		text-align: center;
		line-height: 35px;
		display: flex;
		align-items: center;
		padding-top: 15px;
		padding-right: 60px;
		padding-left: 60px;
	}
	* {
		/* border: solid 1px red; */
	}

	.selector {
		height: 460px;
		display: flex;
		justify-content: space-between;
		padding: 13px 20px 13px 50px;

		.match-sections {
			color: white;
			width: 135px;

			hr {
				border: none;
				border-top: dashed 2px;
				margin-top: 25px;
				margin-bottom: 15px;
			}

			.half {
				.half-type {
					text-transform: uppercase;
					text-align: center;
					height: 30px;
					font-family: "lato";
				}

				.section {
					background: white;
					height: 50px;
					margin-bottom: 7px;
					color: grey;
					font-family: "lato";
					font-size: 1.12rem;
					display: flex;
					align-items: center;
					justify-content: center;
					text-transform: uppercase;
					border-radius: 6px;
					user-select: none;
					cursor: pointer;
				}

				.active {
					background: #47b6e3;
					color: white;
				}
			}
		}
	}

	.footer {
		height: 55px;
		display: flex;
		margin: 0 6px;
		justify-content: space-evenly;
	}
`;
