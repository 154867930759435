import React from "react";

import { Link } from "react-router-dom";
import { Page } from "./style";
import { Header, TimeWithButton } from "../style";
import Icon from "../../svg";

const Home: React.FC = () => {
	return (
		<Page>
			<div className="container">
				<Header>
					<div className="content">Golden Goal</div>
				</Header>
				<div className="showcase">
					Pick the first goalscorer and the minute of the first goal
				</div>
				<div className="fixture">
					<Icon />
					<div className="item side home">Tottenham</div>
					<div className="item vs">vs.</div>
					<div className="item side away">Chelsea</div>
					<Icon inverted={true} />
				</div>
				<div className="prize">$50,000</div>
				<TimeWithButton>
					<div className="time-container">
						<div className="time">
							1<span>d</span> 06<span>h</span> 10<span>m</span>
						</div>
						<div className="prompt">left to edit</div>
					</div>
					<Link to="/player">
						<button className="play">Play now</button>
					</Link>
				</TimeWithButton>
			</div>
		</Page>
	);
};

export default Home;
