import React, { useState, useContext } from "react";
import { ChoiceContext } from "../../contexts/ChoiceContext";

import { Link } from "react-router-dom";
import { Page, PopBar } from "./style";
import { Button, BlueButton } from "../style";

const Player: React.FC = () => {
	const [players] = useState([
		{ name: "Kane", odds: "1/3", popularity: 36 },
		{ name: "Eriksen", odds: "1/3", popularity: 30 },
		{ name: "Alli", odds: "1/3", popularity: 22 },
		{ name: "Lamela", odds: "1/3", popularity: 18 },
		{ name: "Lamela", odds: "1/3", popularity: 18 },
		{ name: "Kane", odds: "1/3", popularity: 36 }
	]);

	const { playerId, playerName, setPlayerId, setPlayerName } = useContext(
		ChoiceContext
	);

	return (
		<Page>
			<div className="container">
				<div className="header">Who will score first?</div>
				<div className="filters">
					<div className="filter active">popular</div>
					<div className="filter">odds</div>
					<div className="filter">bankers</div>
					<div className="filter">long shots</div>
				</div>
				<div className="players">
					<div className="header-row">
						<div className="header-cell">player</div>
						<div className="header-cell">odds</div>
						<div className="header-cell">popularity</div>
					</div>
					{players.map(({ name, odds, popularity }, i) => (
						<div
							className={`player ${i === playerId && "active"}`}
							onClick={() => {
								setPlayerId(i);
								setPlayerName(players[i].name);
							}}
						>
							<div className="name">{name}</div>
							<div className="odds">{odds}</div>
							<PopBar value={popularity}>
								<p>{popularity}%</p>
								<div className="bar">
									<div className="small-bar" />
								</div>
							</PopBar>
						</div>
					))}
				</div>
				<div className="footer">
					<Link to="/">
						<Button>Back</Button>
					</Link>
					<Link to="/minute">
						<BlueButton disabled={!(playerId >= 0) && true}>
							Next
						</BlueButton>
					</Link>
				</div>
			</div>
		</Page>
	);
};

export default Player;
