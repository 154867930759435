import React, { useContext, useState } from "react";

import { Link } from "react-router-dom";
import { ChoiceContext } from "../../contexts/ChoiceContext";

import { Page, Switch } from "./style";
import { Header, TimeWithButton } from "../style";

const Summary: React.FC = () => {
	const { minute, playerName } = useContext(ChoiceContext);
	const [optedIn, setOptedIn] = useState(false);

	return (
		<Page>
			<div className="card">
				<Header small={true}>
					<div className="content">Golden Goal</div>
				</Header>
				<div className="subheader">pick the scorer & minute</div>
				<div className="fixture">
					<div className="side home">tottenham</div>
					<div className="vs">vs.</div>
					<div className="side away">chelsea</div>
				</div>
				<div className="prize">$50,000</div>
				<div className="picks">
					<div className="player pick">{playerName}</div>
					<div className="minute pick">{minute}'</div>
				</div>
				<TimeWithButton>
					<div className="time-container">
						<div className="time">
							1<span>d</span> 06<span>h</span> 10<span>m</span>
						</div>
						<div className="prompt">left to edit</div>
					</div>
					<Link to="/player">
						<button>edit prediction</button>
					</Link>
				</TimeWithButton>
				<div className="optin">
					Keep me updated on my predictions{" "}
					<Switch
						optedIn={optedIn}
						onClick={() => setOptedIn(!optedIn)}
					>
						<div className="inner-circle" />
					</Switch>
				</div>
			</div>
		</Page>
	);
};

export default Summary;
