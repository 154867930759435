import React from "react";

interface IconProps {
	inverted?: boolean;
}

const Icon: React.FC<IconProps> = ({ inverted }) => (
	<svg
		preserveAspectRatio="xMidYMid meet"
		viewBox="0 0 50 50"
		width="50"
		height="50"
	>
		<defs>
			<path d="M55.13 16.56L36.89 47.76" id="g8cpUwXBu" />
			<path
				d="M24.73 47.76L49.05 5.2L31.74 5.2L6.49 47.76L24.73 47.76Z"
				id="m36dN2lui"
			/>
			<path
				d="M12.25 47.76L30.49 17.36L18.33 17.36L0.09 47.76L12.25 47.76Z"
				id="i2P8KiL6q"
			/>
		</defs>
		<g>
			<g>
				<g>
					<g>
						<use
							xlinkHref="#g8cpUwXBu"
							opacity="1"
							fillOpacity="0"
							stroke="#000000"
							strokeWidth="0"
							strokeOpacity="1"
						/>
					</g>
				</g>
				<g>
					<use
						xlinkHref="#m36dN2lui"
						opacity="1"
						fill={inverted ? "#ffffff" : "#124287"}
						fillOpacity="1"
					/>
					<g>
						<use
							xlinkHref="#m36dN2lui"
							opacity="1"
							fillOpacity="0"
							stroke="#000000"
							strokeWidth="0"
							strokeOpacity="1"
						/>
					</g>
				</g>
				<g>
					<use
						xlinkHref="#i2P8KiL6q"
						opacity="1"
						fill={inverted ? "#124287" : "#ffffff"}
						fillOpacity="1"
					/>
					<g>
						<use
							xlinkHref="#i2P8KiL6q"
							opacity="1"
							fillOpacity="0"
							stroke="#000000"
							strokeWidth="0"
							strokeOpacity="1"
						/>
					</g>
				</g>
			</g>
		</g>
	</svg>
);

Icon.defaultProps = {
	inverted: false
};

export default Icon;
