import styled from "styled-components";

export const Selector = styled.div`
	width: 160px;
	margin: 30px 0 5px 0;
	background: #47b6e3;
	border-radius: 6px;
	overflow-y: scroll;
`;

export const Minutes = styled.div`
	padding: 185px 0 130px 0;
`;

interface MinuteProps {
	diff: number;
}

export const Minute = styled.div<MinuteProps>`
	text-align: center;
	font-size: 7rem;
	height: 80px;
	color: white;
	user-select: none;
	opacity: ${({ diff }) => Math.min(1, Math.max(0.1, 1 - diff * 0.49))};
	transition: opacity 0.3s ease-in-out;
`;
