import styled from "styled-components";

interface PopBarProps {
	readonly value: number;
}

export const PopBar = styled.div<PopBarProps>`
	display: flex;
	align-items: center;

	p {
		font-family: "lato";
		margin: 0;
		margin-right: 3px;
		margin-bottom: 2px;
	}

	.bar {
		width: 100px;
		height: 8px;
		margin-right: 17px;
		border: 1px solid #47b6e3;
		border-radius: 4px;

		.small-bar {
			height: 100%;
			border-radius: 2px;
			width: ${({ value }) => value}%;
			background: #47b6e3;
		}
	}
`;

export const Page = styled.div`
	height: 100vh;
	width: 100vw;
	background: #1c1f28;

	.container {
		.header {
			height: 60px;
			padding-top: 12px;
			color: white;
			font-size: 2.2rem;
			text-transform: uppercase;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.filters {
			height: 40px;
			display: flex;
			padding-left: 5px;

			.filter {
				font-family: "lato", Verdana, Geneva, Tahoma, sans-serif;
				background: white;
				padding: 5px 14px;
				margin: 4px 4px;
				text-transform: uppercase;
				font-size: 0.8rem;
				border-radius: 6px;
				color: grey;
			}

			.active {
				background: #47b6e3;
				color: white;
			}
		}

		.players {
			height: 460px;
			.header-row {
				height: 35px;
				display: flex;
				justify-content: space-between;
				color: white;
				padding-left: 24px;
				padding-right: 27px;

				.header-cell {
					font-family: "lato", Verdana, Geneva, Tahoma, sans-serif;
					text-transform: uppercase;
					font-size: 0.75rem;
					margin-top: 6px;
				}
			}
			.player {
				height: 56px;
				margin-bottom: 7px;
				display: flex;
				justify-content: space-between;
				margin-left: 8px;
				margin-right: 8px;
				background: white;
				border-radius: 8px;
				align-items: center;
				padding-left: 15px;
				padding-top: 3px;
				cursor: pointer;

				.name {
					text-transform: uppercase;
					font-size: 2.2rem;
					width: 103px;
				}

				.odds {
					font-family: "lato";
				}
			}

			.active {
				border: #47b6e3 4px solid;
			}
		}

		.footer {
			height: 55px;
			display: flex;
			justify-content: space-evenly;
			margin: 0 6px;
		}
	}
`;
