import React from "react";

import { ChoiceContextProvider } from "./contexts/ChoiceContext";
import { BrowserRouter as Router, Route } from "react-router-dom";

import Home from "./pages/home";
import Player from "./pages/player";
import Minute from "./pages/minute";
import Summary from "./pages/summary";

const App: React.FC = () => {
	return (
		<Router>
			<div>
				<Route path="/" exact component={Home} />
				<ChoiceContextProvider>
					<Route path="/player" component={Player} />
					<Route path="/minute" component={Minute} />
					<Route path="/summary" component={Summary} />
				</ChoiceContextProvider>
			</div>
		</Router>
	);
};

export default App;
