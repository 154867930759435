import React, { useState } from "react";

interface ChoiceTypes {
	playerId: number;
	playerName: string;
	minute: number;
	setPlayerId(i: number): void;
	setPlayerName(p: string): void;
	setMinute(m: number): void;
}

interface ProviderProps {
	children: React.ReactNode;
	value?: any;
}
export const ChoiceContext = React.createContext<ChoiceTypes>({
	playerId: -1,
	playerName: "",
	minute: 0,
	setPlayerId: () => {},
	setPlayerName: () => {},
	setMinute: () => {}
});

export const ChoiceContextProvider: React.FC<ProviderProps> = props => {
	const [playerId, setPlayerId] = useState(-1);
	const [playerName, setPlayerName] = useState("");
	const [minute, setMinute] = useState(0);

	return (
		<ChoiceContext.Provider
			value={{
				playerId,
				playerName,
				minute,
				setPlayerId,
				setPlayerName,
				setMinute
			}}
		>
			{props.children}
		</ChoiceContext.Provider>
	);
};
