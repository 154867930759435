import styled from "styled-components";

export const Page = styled.div`
	height: 100vh;
	width: 100vw;
	background: lightgrey;
	display: flex;
	align-items: center;
	justify-content: center;

	.container {
		background: #0f204c;

		border-radius: 0.5rem;
		width: 310px;
		height: 530px;

		.header {
			height: 70px;
			text-align: center;
			color: white;
			text-transform: uppercase;
			font-size: 2.5rem;
			display: flex;
			align-items: center;
			justify-content: center;

			.content {
				margin-top: 20px;
				position: relative;
				z-index: 1;
			}

			.content:before {
				content: "";
				position: absolute;
				top: -17px;
				left: -45px;
				right: 0;
				bottom: 0;
				background: #47b6e3;
				z-index: -1;
				width: 227px;
				height: 51px;
				padding: 1rem;
				transform: skew(-28deg);
			}
		}

		.showcase {
			padding: 0px 20px;
			height: 130px;
			text-transform: uppercase;
			color: white;
			text-align: center;
			font-size: 2.5rem;
			display: flex;
			align-items: center;
			line-height: 2.2rem;
		}

		.fixture {
			height: 140px;
			background: #03060f;
			color: white;
			display: flex;
			flex-direction: column;

			svg {
				display: block;
				margin: auto;
				width: 27px;
				height: 27px;
				padding-bottom: 7px;
			}

			.item {
				text-align: center;
			}

			.side {
				font-size: 2.2rem;
				text-transform: uppercase;
				height: 20px;
			}
		}

		.prize {
			height: 105px;
			font-size: 6rem;
			color: white;
			text-align: center;
			padding-top: 45px;
		}

		.footer {
			height: 85px;
			display: flex;
			width: 100%;

			.time {
				width: 95px;
				background: #03060f;
				height: 43px;
				margin-left: 15px;
				margin-right: 5px;
				border-radius: 4px;
				color: white;
				text-align: center;
				padding-top: 5px;

				.clock {
					font-size: 1.5rem;
					margin: 0;
				}

				.tip {
					margin-top: -8px;
					text-transform: uppercase;
					font-weight: 10;
				}
			}

			.play {
				width: 178px;
				height: 43px;
				background: #47b6e3;
				border: none;
				border-radius: 4px;
				color: white;

				:active {
					-webkit-box-shadow: inset 0px 0px 10px 0px
						rgba(0, 0, 0, 0.4);
					-moz-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
					box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
				}
			}
		}
	}
`;
